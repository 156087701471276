// @flow

import clipboardCopy from 'clipboard-copy';

/**
 * A helper function that behaves similar to Object.assign, but only reassigns a
 * property in target if it's defined in source.
 *
 * @param {Object} target - The target object to assign the values into.
 * @param {Object} source - The source object.
 * @returns {Object}
 */
export function assignIfDefined(target: Object, source: Object) {
    const to = Object(target);

    for (const nextKey in source) {
        if (source.hasOwnProperty(nextKey)) {
            const value = source[nextKey];

            if (typeof value !== 'undefined') {
                to[nextKey] = value;
            }
        }
    }

    return to;
}

/**
 * Tries to copy a given text to the clipboard.
 * Returns true if the action succeeds.
 *
 * @param {string} textToCopy - Text to be copied.
 * @returns {Promise<boolean>}
 */
export async function copyText(textToCopy: string) {
    try {
        await clipboardCopy(textToCopy);

        return true;
    } catch (e) {
        return false;
    }
}

/**
 * Creates a deferred object.
 *
 * @returns {{promise, resolve, reject}}
 */
export function createDeferred(): Object {
    const deferred = {};

    deferred.promise = new Promise((resolve, reject) => {
        deferred.resolve = resolve;
        deferred.reject = reject;
    });

    return deferred;
}

const MATCH_OPERATOR_REGEXP = /[|\\{}()[\]^$+*?.-]/g;

/**
 * Escape RegExp special characters.
 *
 * Based on https://github.com/sindresorhus/escape-string-regexp.
 *
 * @param {string} s - The regexp string to escape.
 * @returns {string}
 */
export function escapeRegexp(s: string) {
    if (typeof s !== 'string') {
        throw new TypeError('Expected a string');
    }

    return s.replace(MATCH_OPERATOR_REGEXP, '\\$&');
}

/**
 * Returns the base URL of the app.
 *
 * @param {Object} w - Window object to use instead of the built in one.
 * @returns {string}
 */
export function getBaseUrl(w: Object = window) {
    const doc = w.document;
    const base = doc.querySelector('base');

    if (base && base.href) {
        return base.href;
    }

    const { protocol, host } = w.location;

    return `${protocol}//${host}`;
}

/**
 * Returns the namespace for all global variables, functions, etc that we need.
 *
 * @returns {Object} The namespace.
 *
 * NOTE: After React-ifying everything this should be the only global.
 */
export function getMeetHourGlobalNS() {
    if (!window.MeetHourJS) {
        window.MeetHourJS = {};
    }

    if (!window.MeetHourJS.app) {
        window.MeetHourJS.app = {};
    }

    return window.MeetHourJS.app;
}

/**
 * Prints the error and reports it to the global error handler.
 *
 * @param {Error} e - The error object.
 * @param {string} msg - A custom message to print in addition to the error.
 * @returns {void}
 */
export function reportError(e: Object, msg: string = '') {
    console.error(msg, e);
    window.onerror && window.onerror(msg, null, null, null, e);
}

type charObjectType = {
    char: string,
    charLength: number,
    shouldBeDotted: boolean
}


/**
 * Slice characters based on length.
 *
 * @param {{
 *  char: string,
 *   charLength: number,
 *  shouldBeDotted: boolean
 * }} charObject - An object for slicing characters based on their length.
 *
 * @returns {any}
 */
export function sliceLengthyCharacters(charObject: charObjectType = {
    char: '',
    charLength: 20,
    shouldBeDotted: false
}) {
    const dotted = '...';
    const { char, charLength, shouldBeDotted } = charObject;

    if (char.length >= charLength && shouldBeDotted) {
        return char.substring(0, charLength) + dotted;
    }

    return char.length >= charLength ? char.substring(0, charLength) : char;

}


/**
 * Sleep for Milliseconds.
 *
 * @param {any} milliseconds - Milliseconds.
 * @returns {any}
 */
export function sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}


/**
 * Remove Duplicates.
 *
 * @param {any} arr - Array.
 * @returns {any}
 */
export function removeDuplicates(arr: Array<any>) {
    return arr.filter((item,
            index) => arr.indexOf(item) === index);
}

/**
 * Check if json can parse.
 *
 * @param {any} str - String.
 * @returns {any}
 */
export function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }

    return true;
}
