// @flow

declare var MeetHourJS: Object;

/**
 * Loads a script from a specific URL. The script will be interpreted upon load.
 *
 * @param {string} url - The url to be loaded.
 * @param {string} id - Id of the script.
 * @returns {Promise} Resolved with no arguments when the script is loaded and
 * rejected with the error from MeetHourJS.ScriptUtil.loadScript method.
 */
export function loadScript(url: string, id: string): Promise<void> {
    return new Promise((resolve, reject) =>
        MeetHourJS.util.ScriptUtil.loadScript(
            url,
            /* async */ true,
            /* prepend */ false,
            /* relativeURL */ false,
            /* loadCallback */ resolve,
            /* errorCallback */ reject,
            id));
}
